import React, {
  useState,
  Fragment
} from "react";
import { Dialog, Transition } from "@headlessui/react";
import Loader from "./Loader";
import { Form, FormInstance, Input, notification } from "antd";
import PhoneInput from "react-phone-number-input";
import api, { accounts_ms } from "../helpers/api";
import { RouteConstants } from "../helpers/constants";
import { useQuery } from "@tanstack/react-query";
import close_icon from "../img/close.svg";
import OTPInput from "../components/OTPInput";

const ChangePinModal = ({
  form,
  isOpen,
  handleClose,
}: {
  isOpen: boolean;
  handleClose: () => void;
  form: FormInstance;
}) => {
  const [loading, setLoading] = useState(false);
  const [phone, setPhone] = useState<any>("");
  const [otpi, setOtpi] = useState("");
  const [step1, setstep1] = useState<boolean>(true);
  const [step2, setstep2] = useState<boolean>(false);
  const [step3, setstep3] = useState<boolean>(false);
  const [pin, setPin] = useState<any>("");
  const [confirm_pin, setConfirmPin] = useState<any>("");

  const { isLoading } = useQuery({
    retry: 1,
    queryKey: ["user-phone"],
    queryFn: async () => {
      try {
        const user = await accounts_ms.get(RouteConstants.PROFILE);
        setPhone(user.data?.payload?.phone);
        return user.data?.payload;
      } catch (e) {
        console.log(e);
      }
    },
  });



  const handleSubmit1 = async () => {
    if (!(pin === confirm_pin)){
      notification.error({message: "PINs do not match"})
      return;
    }
    setLoading(true);
    try {
      const response = await api
        .post(RouteConstants.RESET_PIN, { phone: phone, code: otpi, pin: pin })
        .then(() => {
          setLoading(false);
          handleClose();
        });

      notification.success({ message: "PIN updated successfully" });
      setstep3(false);
      setstep1(true);
      setPin("");
      setConfirmPin("")
      console.log(response)
    } catch (e) {
      notification.error({ message: "Could not update PIN" });
      setstep3(false);
      setstep1(true);
      setPin("");
      setConfirmPin("")
      handleClose();
      setLoading(false);
      console.log(e);
    }
  };

  
  const handleSubmit = async () => {
    setLoading(true);
    try {
      const response = await api
        .post(RouteConstants.REQUEST_RESET_PIN_OTP, { phone: phone })
        .then(() => {
          setstep1(false);
          setstep2(true);
          setLoading(false);
        });
      console.log(response);
    } catch (e) {
      notification.error({ message: "Could not update PIN" });
      console.log(e);
      setLoading(false)
      handleClose();
    }
  };

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50 " onClose={handleClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-800 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto ">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full  max-w-3xl  transform overflow-hidden bg-white rounded-2xl shadow-xl transition-all">
                  <div className="grid grid-cols-3 bg-[#F5F5F5] p-5 items-center">
                    <div
                      className="flex flex-row items-center gap-2 text-left cursor-pointer"
                      onClick={() => {
                        handleClose();
                        setstep1(true);
                        setstep2(false);
                        setstep3(false);
                      }}
                    >
                      <img src={close_icon} alt="Close" className="w-5 h-5" />{" "}
                      <p>Close</p>
                    </div>
                    <div className="mx-auto">Verify Phone Number</div>
                    <div></div>
                  </div>
                  {step1 && (
                    <section className="p-5">
                      <div>
                        <Form
                          //  form={form}
                          layout="vertical"
                          className="w-full"
                          onFinish={() => handleSubmit()}
                        >
                          <div className="w-full flex items-center flex-wrrap">
                            <Form.Item
                              label={
                                <label
                                  htmlFor="phone"
                                  className="block capitalize text-sm text-gray-601"
                                >
                                  Phone Number
                                </label>
                              }
                              className="w-full"
                              colon={false}
                              rules={[
                                {
                                  required: true,
                                  message: "This field is required",
                                },
                              ]}
                            >
                              {isLoading ? (
                                <></>
                              ) : (
                                <PhoneInput
                                  id="phone"
                                  className="phone w-full px-4 mt-2 mb-4 border border-gray-200 rounded-md outline-none h-10"
                                  data-testid="edit-profile-emergency-1-input"
                                  defaultCountry="GH"
                                  name="phone"
                                  value={phone}
                                  onChange={(e) => setPhone(e)}
                                />
                              )}
                            </Form.Item>
                          </div>
                        </Form>
                      </div>
                      <div className="w-full flex justify-end items-center mt-6 mb-6 text-[#B40303] ">
                        <div className="mr-2">
                          <button
                            onClick={handleClose}
                            grid-rows-1
                            className="rounded-lg border border-[#B40303] font-bold capitalize px-8 py-2"
                            disabled={loading}
                            type="button"
                          >
                            cancel
                          </button>
                        </div>
                        <div>
                          <button
                            type="submit"
                            className="rounded-lg flex bg-red-101 text-white capitalize px-8 py-2"
                            disabled={loading}
                            onClick={() => handleSubmit()}
                          >
                            {loading && <Loader />}
                            Send OTP{" "}
                          </button>
                        </div>
                      </div>
                    </section>
                  )}
                  {step2 && (
                    <>
                      <section className="p-5">
                        <span className="block text-left text-gray-500 text-sm mb-2">
                          Enter OTP Sent To Your {phone}
                        </span>

                        <div className="block">
                          <OTPInput onComplete={()=>{setOtpi(pin)}} />
                        </div>
                        <div className="w-full flex justify-end items-center mt-6 mb-6 text-[#B40303] ">
                          <div className="mr-2">
                            <button
                              onClick={() => {
                                handleClose();
                                setstep2(false);
                                setstep1(true);
                              }}
                              className="rounded-lg border border-[#B40303] font-bold capitalize px-8 py-2"
                              disabled={loading}
                              type="button"
                            >
                              Cancel
                            </button>
                          </div>
                          <div>
                            <button
                              type="submit"
                              className="rounded-lg flex bg-red-101 text-white capitalize px-8 py-2"
                              disabled={loading}
                              onClick={()=>{setstep2(false);setstep3(true)}}
                            >
                              {loading && <Loader />}
                              Verify
                            </button>
                          </div>
                        </div>
                      </section>
                    </>
                  )}
                  {step3 && (
                    <section className="p-5">
                      <div>
                        <Form
                          layout="vertical"
                          className="w-full"
                          onFinish={() => handleSubmit1()}
                        >
                          <div className="w-full flex items-center flex-wrrap">
                            <Form.Item
                              label={
                                <label
                                  htmlFor="phone"
                                  className="block capitalize text-sm text-gray-601"
                                >
                                  New Pin{" "}
                                </label>
                              }
                              className="w-full"
                              colon={false}
                              rules={[
                                {
                                  required: true,
                                  message: "This field is required",
                                },
                              ]}
                            >
                              <Input.Password
                                id="pin"
                                className="phone w-full px-4 mt-2 mb-4 border border-gray-200 rounded-md outline-none h-10"
                                data-testid="edit-profile-emergency-1-input"
                                name="pin"
                                value={pin}
                                onChange={(e) => setPin(e.target.value)}
                              />
                            </Form.Item>
                          </div>
                          <div className="w-full flex items-center flex-wrrap">
                            <Form.Item
                              label={
                                <label
                                  htmlFor="phone"
                                  className="block capitalize text-sm text-gray-601"
                                >
                                  Confirm Pin{" "}
                                </label>
                              }
                              className="w-full"
                              colon={false}
                              rules={[
                                {
                                  required: true,
                                  message: "This field is required",
                                },
                              ]}
                            >
                              <Input.Password
                                id="confirm_pin"
                                className="phone w-full px-4 mt-2 mb-4 border border-gray-200 rounded-md outline-none h-10"
                                data-testid="edit-profile-emergency-1-input"
                                name="confirm_pin"
                                value={confirm_pin}
                                onChange={(e) => setConfirmPin(e.target.value)}
                              />
                            </Form.Item>
                          </div>
                        </Form>
                      </div>
                      <div className="w-full flex justify-end items-center mt-6 mb-6 text-[#B40303] ">
                        <div className="mr-2">
                          <button
                            onClick={() => {
                              handleClose();
                              setstep3(false);
                              setstep1(true);
                            }}
                            className="rounded-lg border border-[#B40303] font-bold capitalize px-8 py-2"
                            disabled={loading}
                            type="button"
                          >
                            cancel
                          </button>
                        </div>
                        <div>
                          <button
                            type="submit"
                            className="rounded-lg flex bg-red-101 text-white capitalize px-8 py-2"
                            disabled={loading}
                            onClick={() => handleSubmit1()}
                          >
                            {loading && <Loader />}
                            Change PIN{" "}
                          </button>
                        </div>
                      </div>
                    </section>
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default ChangePinModal;

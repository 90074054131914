import { useState } from "react";
import { Navigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import Skeleton from "react-loading-skeleton";
import axios from "axios";
import { RouteConstants } from "../../helpers/constants";
import cookies from "js-cookie";
import { useStore } from "../../helpers/zustand";
import { COOKIES_USR, COOKIES_TOKEN_NAME } from "../../helpers/constants";
import { notification, Avatar } from "antd";
import { accounts_ms } from "../../helpers/api";
import EditProfileModal from "../../components/EditProfileModal";
import Navbar from "../../components/Navbar";
import Sidenav from "../../components/Sidenav";
import { PiPencilSimple } from "react-icons/pi";
import ProfilePhotoUpload from "../../components/ProfilePhotoUpload";

const Main = () => {
  const userId = cookies.get(`${COOKIES_USR}`);
  const token = cookies.get(`${COOKIES_TOKEN_NAME}`);
  const [editProfileModal, openEditProfileModal] = useState<boolean>(false);
  const [profileUploadModal, openProfileUploadModal] = useState<boolean>(false);
  const [signing, setSigning] = useState(false);

  // const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState({
    first_name: "",
    last_name: "",
    middle_name: "",
    ice1_phone: "",
    ice2_phone: "",
    phone: "",
    birthdate: "",
    gender: "",
    email: "",
    image: "",
    country: {
      code: "",
      name: "",
      phone_code: "",
    },
    payment_account_number: "",
    payment_account_provider: "",
  });
  const [setImgUrl, setUserName] = useStore((state: any) => [
    state.setImgUrl,
    state.setUserName,
  ]);

  const { isLoading, error } = useQuery({
    retry: 0,
    queryKey: ["user-details-main"],
    queryFn: async () => {
      if (token && userId) {
        try {
          const user = await accounts_ms.get(RouteConstants.PROFILE);
          // console.log(user.data);
          setDetails(user.data?.payload);
          setImgUrl(user.data?.payload?.image);
          setUserName(user.data?.payload?.name);
          console.log("User Details",user.data?.payload )
          return user.data?.payload;
        } catch (e: any) {
          notification.error({
            message: e?.message,
          });
          console.log(e);
        }
      }
    },
  });
  if (error) {
    console.log(error);
    //notification.error({ message: "Error fetching user details" });
  }

  const uploadSignedImage = async (file: any) => {
    try {
      setSigning(true);
      const response = await accounts_ms.post(
        RouteConstants.PHOTO_SIGNED_UPLOAD,
        {
          mime: file?.type,
        }
      );
      console.log(response);
      axios
        .put(response.data?.payload?.upload_url, file, {
          headers: {
            "Content-Type": file?.type,
          },
        })
        .then(function () {
          setSigning(false);

          notification.success({
            message: "Profile photo updated",
          });
          openProfileUploadModal(false);
          window.location.reload();
        })
        .catch(function (error) {
          console.log(error);
        });
    } catch (error) {
      setSigning(false);
      console.log("Error signing profile :", error);
      setSigning(false);
    }
  };

  const deleteProfilePhoto = async () => {
    try {
      const response = await accounts_ms
        .delete(RouteConstants.DELETE_PROFILE_PHOTO)
        .then(() => {
          notification.success({ message: "Profile photo deleted" });
          window.location.reload();
        })
        .catch((e) => {
          console.log(e);
        });
      console.log(response);
    } catch (e) {
      console.log("Error deleting profile :", error);
    }
  };

  if (token && userId) {
    return (
      <>
        <div>
          <Navbar
            title="Profile"
            button={
              <>
                <div
                  className="flex flex-row items-center gap-1 font-semibold text-[#B40303] rounded-lg border border-[#B40303] p-2"
                  onClick={() => openEditProfileModal(true)}
                >
                  <PiPencilSimple /> <p className="text-[12px]">Edit profile</p>
                </div>
              </>
            }
          />
          <Sidenav />
          <section>
            <div className="w-full md:w-[79%] lg:w-[88.3%] float-right">
              <div className="flex flex-col items-start flex-wrap w-full mt-[110px] sm:mt-[150px] mb-14 sm:mb-8 gap-7 px-[2%]">
                <div className="w-full  border-[1px] border-[#D4D3D3] rounded-[30px]   pr-5 pl-1 shadow-md">
                  {isLoading ? (
                    <div className="flex gap-4 items-center px-5 py-8">
                      <Skeleton circle={true} width={60} height={60} />
                      <div className="bg-gray-300 animate-pulse w-[200px] h-5 rounded-sm text-transparent"></div>
                    </div>
                  ) : (
                    <div className="transition-all duration-1000 ease-out w-full">
                      <div>
                        {details?.image ? (
                          <div className="flex justify-between items-center">
                            <div className="flex flex-row gap-5 items-center p-5">
                              <Avatar
                                src={details?.image}
                                alt="Profile"
                                size={{
                                  xl: 96,
                                  xs: 80,
                                  md: 96,
                                  xxl: 100,
                                  lg: 96,
                                }}
                              />{" "}
                              <div className="flex flex-col">
                                <div className="capitalize sm:text-oya-ghana-header-h5  text-2xl text-[#484442]">
                                  {details?.first_name}{" "}
                                  {!details?.middle_name
                                    ? ""
                                    : details?.middle_name}{" "}
                                  {details?.last_name}
                                </div>
                              </div>
                            </div>
                            <div className="">
                              <button
                                onClick={() => openProfileUploadModal(true)}
                                className="bg-[#B40303] capitalize rounded-lg text-white py-2 px-3 md:py-3 md:px-7 mb-2 md:mb-0 md:mr-4 font-medium"
                              >
                                Change photo
                              </button>
                              <button
                                className="border-[#B40303] border-[1px] text-[#B40303] py-2 px-3 md:py-3 md:px-7 rounded-lg font-medium"
                                onClick={deleteProfilePhoto}
                              >
                                Remove photo
                              </button>
                            </div>
                          </div>
                        ) : (
                          <div className="flex justify-between items-center">
                            <div className="flex flex-row gap-5 items-center p-5">
                              {isLoading ? (
                                <div className="flex gap-4 items-center">
                                  <Skeleton
                                    circle={true}
                                    width={30}
                                    height={30}
                                  />
                                  <div className="bg-gray-300 animate-pulse w-[200px] h-5 rounded-sm text-transparent"></div>
                                </div>
                              ) : (
                                <img
                                  src={require("../../img/User-120.png")}
                                  alt="Profile"
                                  className="w-16 h-16 md:w-24 md:h-24"
                                />
                              )}
                              <div className="flex flex-col">
                                {isLoading ? (
                                  <Skeleton className="w-2/5" />
                                ) : (
                                  <>
                                    <div className="capitalize sm:text-oya-ghana-header-h5  text-2xl text-[#484442]">
                                      {details?.first_name}{" "}
                                      {!details?.middle_name
                                        ? ""
                                        : details?.middle_name}{" "}
                                      {details?.last_name}
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                            <div>
                              <button
                                onClick={() => openProfileUploadModal(true)}
                                className="bg-[#B40303] rounded-lg text-white py-1 px-1 md:py-3 md:px-7 font-medium"
                              >
                                Upload photo
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>

                {/* details */}
                <div className="mt-4 border-[1px] border-[#D4D3D3] rounded-[30px] p-5 w-full font-semibold">
                  <p className="font-bold text-2xl text-[#484442] mb-7">
                    Basic Information
                  </p>

                  <div className="grid grid-rows-1 md:grid-cols-2 gap-5 mb-7">
                    <div
                      className="flex flex-col w-full"
                      data-testid="first-name-profile"
                    >
                      <p className="text-md text-[#939291]">Name</p>
                      {isLoading ? (
                        <div className="bg-gray-300 animate-pulse w-[200px] h-5 rounded-sm text-transparent"></div>
                      ) : (
                        <p className="text-[#484442]">
                          {details?.first_name}{" "}
                          {!details?.middle_name ? "" : details?.middle_name}{" "}
                          {details?.last_name}
                        </p>
                      )}
                    </div>

                    <div className="w-full flex flex-col text-md">
                      <p className="text-md text-[#939291]">Date Of Birth</p>
                      {isLoading ? (
                        <div className="bg-gray-300 animate-pulse w-[200px] h-5 rounded-sm text-transparent"></div>
                      ) : (
                        <p className="text-[#484442]">
                          {!details?.birthdate ? "N/A" : details?.birthdate}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="grid grid-cols-2 gap-4">
                    <div className="w-full flex flex-col text-md">
                      <p className=" text-[#939291]">Gender</p>
                      {isLoading ? (
                        <div className="bg-gray-300 animate-pulse w-[200px] h-5 rounded-sm text-transparent"></div>
                      ) : (
                        <p>
                          {!details?.gender
                            ? "N/A"
                            : details?.gender === "M"
                            ? "Male"
                            : details?.gender === "F"
                            ? "Female"
                            : "Other"}
                        </p>
                      )}
                    </div>

                    <div className="w-full flex flex-col text-md">
                      <p className="text-[#939291]">Country</p>
                      {isLoading ? (
                        <div className="bg-gray-300 animate-pulse w-[200px] h-5 rounded-sm text-transparent"></div>
                      ) : (
                        <p className="text-[#484442]">
                          {details?.country?.name}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="mt-4 border-[1px] border-[#D4D3D3] rounded-[30px] font-semibold  p-5 w-full">
                  <p className="font-bold text-2xl  text-[#484442] mb-7">
                    Contact Information
                  </p>

                  <div className="grid grid-rows-1 md:grid-cols-2 gap-5 mb-7">
                    <div className="w-full flex flex-col text-md">
                      <p className="text-[#939291]">Phone Number</p>
                      {isLoading ? (
                        <div className="bg-gray-300 animate-pulse w-[200px] h-5 rounded-sm text-transparent"></div>
                      ) : (
                        <p className="text-[#484442]">{details?.phone}</p>
                      )}
                    </div>

                    <div className="w-full flex flex-col text-md">
                      <p className="text-[#939291]">Email Address</p>
                      {isLoading ? (
                        <div className="bg-gray-300 animate-pulse w-[200px] h-5 rounded-sm text-transparent"></div>
                      ) : (
                        <p className="text-[#484442]">
                          {details?.email ? details?.email : "N/A"}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="grid grid-cols-2 gap-4">
                    <div className="w-full flex flex-col text-md">
                      <p className="text-[#939291]">Emergency contact 1</p>
                      {isLoading ? (
                        <div className="bg-gray-300 animate-pulse w-[200px] h-5 rounded-sm text-transparent"></div>
                      ) : (
                        <p className="text-[#484442]">{details.ice1_phone}</p>
                      )}
                    </div>

                    <div className="w-full flex flex-col text-md">
                      <p className="text-[#939291]">Emergency contact 2</p>
                      {isLoading ? (
                        <div className="bg-gray-300 animate-pulse w-[200px] h-5 rounded-sm text-transparent"></div>
                      ) : (
                        <p className="text-[#484442]">{details.ice2_phone}</p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="mt-4 border-[1px] border-[#D4D3D3] rounded-[30px] font-semibold p-5 w-full">
                  <p className="font-bold text-2xl text-[#484442] mb-7">
                    Payment Information
                  </p>

                  <div className="grid grid-cols-2 gap-4">
                    <div className="flex flex-col text-md">
                      <p className="text-[#939291]">Payment Provider </p>
                      {isLoading ? (
                        <div className="bg-gray-300 animate-pulse w-[200px] h-5 rounded-sm text-transparent"></div>
                      ) : (
                        <p className="text-[#484442] capitalize">
                          {!details?.payment_account_provider
                            ? "N/A"
                            : details?.payment_account_provider}
                        </p>
                      )}
                    </div>

                    <div className="flex flex-col">
                      <p className="text-md text-[#939291]">Phone Number</p>
                      {isLoading ? (
                        <div className="bg-gray-300 animate-pulse w-[200px] h-5 rounded-sm text-transparent"></div>
                      ) : (
                        <p className="text-[#484442]">
                          {details?.payment_account_number}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* </MainLayout> */}
            </div>
            <EditProfileModal
              isOpen={editProfileModal}
              handleClose={() => openEditProfileModal(false)}
            />
            <ProfilePhotoUpload
              isOpen={profileUploadModal}
              handleClose={() => openProfileUploadModal(false)}
              handleSubmit={uploadSignedImage}
              signing={signing}
              loading={isLoading}
            />
          </section>
        </div>
      </>
    );
  } else {
    return <Navigate to="/login" />;
  }
};

export default Main;

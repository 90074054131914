import { useState } from "react";
import { Navigate } from "react-router-dom";
import cookies from "js-cookie";
import { useQuery } from "@tanstack/react-query";
import {
  COOKIES_USR,
  COOKIES_TOKEN_NAME,
  url,
  ACCOUNTS_MS_API_URL,
} from "../../helpers/constants";
import Skeleton from "react-loading-skeleton";
import axios from "axios";
import EditVerificationModal from "../../components/EditVerificationModal";
import Navbar from "../../components/Navbar";
import Sidenav from "../../components/Sidenav";

function Verification() {
  const [verificationModal, openVerifcationModal] = useState<boolean>(false);
  const userId = cookies.get(`${COOKIES_USR}`);
  const token = cookies.get(`${COOKIES_TOKEN_NAME}`);
  const [verification_details, setVerificationDetails] = useState({
    id: "",
    name: "",
    serial: "",
    front_image_url: "",
    back_image_url: "",
    verified: "",
    verified_at: "",
    country: {
      code: "",
      name: "",
    },
    issued_at: "",
    expires_at: "",
  });

  const { isLoading } = useQuery({
    queryKey: ["verification-details"],
    queryFn: async () => {
      const user = await axios.get(`${ACCOUNTS_MS_API_URL}/v1/identification`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setVerificationDetails(user.data?.payload);
      //console.log(user.data);
      return user.data;
    },
  });

  const expiresAt = new Date(
    verification_details?.expires_at
  ).toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });

  const issuedAt = new Date(verification_details?.issued_at).toLocaleDateString(
    "en-US",
    {
      year: "numeric",
      month: "short",
      day: "numeric",
    }
  );

  if (userId && token) {
    return (
      <>
        <div>
          <Navbar title="Verification" /> <Sidenav />
          <section className="w-full md:w-[79%] lg:w-[88.3%] float-right  px-[2%]">
            <div className="w-full mt-[110px] md:mt-[150px] mb-7 rounded-[30px] border-[1px] border-[#D4D3D3] p-5 ">
              {verification_details?.serial ? (
                <>
                  {" "}
                  <div className=" w-full flex flex-col flex-wrap mt-0 md:mt-0">
                    <p className="font-bold text-2xl text-[#484442] mb-7">
                      National ID
                    </p>
                    <div className=" w-[250px] sm:mr-10">
                      <div className="capitalize text-[#939291]">
                        ID Card Number
                      </div>
                      <div className="font-bold text-[#484442]">
                        {!verification_details?.serial
                          ? "-"
                          : verification_details?.serial}{" "}
                      </div>
                    </div>
                  </div>
                  <div className="mt-4 w-full grid grid-rows-2 md: gap-2 md:gap-0 md:grid-cols-2">
                    <div className="mr-4">
                      <div className="capitalize text-[#939291]">
                        Issued Date
                      </div>
                      <div className="font-semibold text-[#484442]">
                        {!verification_details.issued_at ? "-" : issuedAt}
                      </div>
                    </div>
                    <div>
                      <div className="capitalize text-[#939291]">
                        Expiry Date
                      </div>
                      <div className="font-semibold text-[#484442]">
                        {!verification_details?.expires_at ? "-" : expiresAt}
                      </div>
                    </div>
                  </div>
                  <div className=" w-full flex gap-7 md:gap-2 flex-wrap pt-5">
                    <div className="w-full md:w-5/12">
                      <div className="w-full">
                        <div className="pb-3 text-[#939291]">
                          Card Front Image
                        </div>
                        {verification_details.front_image_url ? (
                          <img
                            src={verification_details.front_image_url}
                            alt="card front"
                          />
                        ) : (
                          <img src={url} alt="card front" />
                        )}
                      </div>
                    </div>
                    <div className="w-full md:w-5/12">
                      <div className="w-full">
                        <div className="pb-3 text-[#939291]">
                          Card Back Image
                        </div>
                        {verification_details.back_image_url ? (
                          <img
                            src={verification_details.back_image_url}
                            alt="card front"
                          />
                        ) : (
                          <img src={url} alt="card front" />
                        )}
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {isLoading ? (
                    <Skeleton count={4} className="w-2/4" />
                  ) : (
                    <>
                      <p className="font-bold text-2xl text-[#484442] mb-7">
                        National ID
                      </p>
                      <p className="text-[#484442] pb-5">
                        You have not uploaded your National ID card
                      </p>

                      <button
                        className="flex flex-row bg-[#B40303] rounded-lg text-white py-3 px-7 gap-3 font-medium"
                        onClick={() => openVerifcationModal(true)}
                      >
                        <img
                          src={require("../../img/export.png")}
                          alt="Upload"
                        />
                        <p>Upload National ID</p>{" "}
                      </button>
                      <EditVerificationModal
                        isOpen={verificationModal}
                        handleClose={() => openVerifcationModal(false)}
                      />
                    </>
                  )}
                </>
              )}
            </div>
          </section>
        </div>
      </>
    );
  } else {
    return <Navigate to="/login" />;
  }
}

export default Verification;

import { NavLink, Link, useLocation } from "react-router-dom";
import Logo from "../img/Oya_logo.svg";
import profile_active from "../img/profile_active.png";
import profile_inactive from "../img/profile_inactive.png";
import lock_active from "../img/lock_active.png";
import lock_inactive from "../img/lock_inactive.png";
import verify_active from "../img/verify_active.png";
import verify_inactive from "../img/verify_inactive.png";

const Sidenav = () => {
  let activeStyle = {
    color: "#B40303",
    backgroundColor: "#FFCECC",
    borderRadius: 12,
    paddingLeft: 25,
    paddingRight: 25,
    paddingTop: 10,
    paddingBottom: 10,
    margin: "auto",
  };

  const location = useLocation();
  const isHome = location.pathname === "/" || location.pathname === "/account";

  return (
    <div className="hidden md:block z-40 bg-white  md:w-[21%] lg:w-[11.7%] h-[100%] fixed border-r-[1px] border-[#D4D3D3]">
      <div className=" w-full">
        <div className="flex flex-col items-center">
          <Link to="/">
            <div className="flex flex-row py-7 items-center mt-2">
              <img className="brand-logo" src={Logo} alt="brand logo" />
              <p className=" font-bold text-2xl font-sans leading-5 tracking-wide pl-2 text-[#484442]">
                Oya!
              </p>
            </div>
          </Link>

          <div className="hidden sm:block  overflow-auto pt-14">
            <div className="sm:block hidden">
              <ul>
                <li>
                  {isHome ? (
                    <NavLink
                      to="/account"
                      className="flex text-md text-red-101 bg-[#FFCECC] font-semibold rounded-xl px-[25px] py-[10px] m-auto "
                    >
                      <img
                        src={profile_active}
                        alt="account profile"
                        className="pr-2"
                      />
                      Profile
                    </NavLink>
                  ) : (
                    <NavLink
                      to="/account"
                      className="flex text-md font-semibold text-oya-ghana-faded-gray px-[25px] py-[10px] "
                    >
                      {" "}
                      <img
                        src={profile_inactive}
                        alt="account profile"
                        className="pr-2"
                      />
                      Profile
                    </NavLink>
                  )}
                </li>
                <li>
                  <NavLink
                    to="/security-details"
                    className="flex text-md font-semibold text-oya-ghana-faded-gray px-[25px] py-[10px]"
                    style={({ isActive }) =>
                      isActive ? activeStyle : undefined
                    }
                  >
                    {({ isActive }) => (
                      <>
                        <img
                          src={isActive ? lock_active : lock_inactive}
                          alt="account profile"
                          className="pr-2"
                        />
                        Security
                      </>
                    )}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/verification"
                    className="flex text-md font-semibold text-oya-ghana-faded-gray px-[25px] py-[10px]"
                    style={({ isActive }) =>
                      isActive ? activeStyle : undefined
                    }
                  >
                    {({ isActive }) => (
                      <>
                        <img
                          src={isActive ? verify_active : verify_inactive}
                          alt="account profile"
                          className="pr-2"
                        />
                        Verification
                      </>
                    )}
                  </NavLink>
                </li>
              </ul>
            </div>{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidenav;

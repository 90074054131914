import React, { useState, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { Form, notification, Input } from "antd";
import ImageUpload from "./ImageUpload";
import axios from "axios";
import { useStore } from "../helpers/zustand";
import { COOKIES_TOKEN_NAME } from "../helpers/constants";
import { ACCOUNTS_MS_API_URL } from "../helpers/constants";
import Loader from "./Loader";
import Cookies from "js-cookie";
import close_icon from "../img/close.svg"

const EditVerificationModal = ({
  isOpen,
  handleClose,
}: {
  isOpen: boolean;
  handleClose: () => void;
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const navigate = useNavigate();
  const token = Cookies.get(`${COOKIES_TOKEN_NAME}`);

  const [cardImages, setCardImage] = useState([
    {
      type: "",
      url: "",
      file: "",
    },
  ]);

  const [user_name] = useStore((state: any) => [state.user_name]);

  const [details, setDetails] = useState({
    id: 3,
    name: "",
    serial: "",
    front_image_url: "",
    back_image_url: "",
    valid: false,
    verified: false,
    verified_at: null,
    country: {
      code: "GH",
      name: "Ghana",
    },
    issued_at: "",
    expires_at: "",
  });

  const handleImageUpload = (type: string, url: string, file: any) => {
    const hasNoImgUrlIndex = cardImages?.findIndex((el) => el.url === "");
    if (hasNoImgUrlIndex >= 0) {
      const images = cardImages.slice(0);
      images[hasNoImgUrlIndex] = { type, url, file };
      setCardImage(images);
    }
  };

  const signImage = async (type: string, id: any, cardImg: any) => {
    return new Promise((resolve, reject) => {
      // 2. Upload image mime type
      if (cardImg?.type === "front") {
        return axios
          .post(
            `${ACCOUNTS_MS_API_URL}/v1/identification/image/presigned-upload`,
            {
              mime: cardImg?.file?.type,
            }
          )
          .then((response) => {
            uploadFileToPresignedUrl(
              "front",
              response.data?.data,
              cardImg?.file
            )
              .then(() => {
                // Proceed to save client_url to state
                resolve(response.data?.data);
              })
              .catch((error) => {
                reject(error);
              });
          })
          .catch((error) => {
            reject(error);
          });
      } else if (cardImg?.type === "back") {
        return axios
          .post(
            `${ACCOUNTS_MS_API_URL}/v1/identification/image/presigned-upload`,
            {
              mime: cardImg?.file?.type,
            }
          )
          .then((response) => {
            uploadFileToPresignedUrl("back", response.data?.data, cardImg?.file)
              .then(() => {
                // Proceed to save client_url to state
                resolve(response.data?.data);
              })
              .catch((error) => {
                reject(error);
              });
          })
          .catch((error) => {
            reject(error);
          });
      }
    });
  };

  const uploadFileToPresignedUrl = async (
    type: string,
    url: any,
    file: any
  ) => {
    if (type === "front") {
      return new Promise((resolve, reject) => {
        return axios
          .put(url?.front?.upload_url, file, {
            headers: {
              "Content-Type": file.type,
            },
          })
          .then(function (response) {
            resolve(JSON.stringify(response.data));
          })
          .catch(function (error) {
            reject(error);
          });
      });
    } else if (type === "back") {
      return new Promise((resolve, reject) => {
        return axios
          .put(url?.back?.upload_url, file, {
            headers: {
              "Content-Type": file.type,
            },
          })
          .then(function (response) {
            resolve(JSON.stringify(response.data));
          })
          .catch(function (error) {
            reject(error);
          });
      });
    }
  };

  const getImageUrls = (id: any) => {
    return new Promise((resolve, reject) => {
      // 1. Get pre-signed url from image url (base64s)
      const promises = cardImages
        ?.filter((_el) => _el.url !== "")
        ?.map(async (el) => {
          try {
            const response = await signImage(el?.type, id, el);
            //const back_res = await signImage(el?.type, id, el);
            return response;
          } catch (e: any) {
            notification.error(e?.message);
          }
        });

      Promise.all(promises)
        .then(
          (res) => {
            setLoading(false);
            resolve(res);
          },
          (error) => {
            setLoading(false);
            reject(error);
          }
        )
        .catch((error) => {
          setLoading(false);
          reject(error);
        });
    });
  };

  const handleSubmit = async (values: any) => {
    //console.log(values);
    setLoading(true);
    setDisabled(true);

    let verificationDetails = {
      name: user_name,
      country_code: "GH",
      serial: values?.serial,
      issued_date: values?.issued_date,
      expiry_date: values?.expiry_date,
    };
    //console.log(verificationDetails);
    try {
      const response = await axios.post(
        `${ACCOUNTS_MS_API_URL}/v1/identification`,
        verificationDetails,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setDisabled(false);
      if (response.data?.status === 200) {
        getImageUrls(response.data?.data?.id)
          .then(() => {
            notification.success({ message: "Identifications uploaded" });
            setLoading(false);
            navigate(-1);
          })
          .catch((e) => {
            notification.error({
              message: e?.message,
            });
          });
      } else {
        console.log(response);
        notification.error({ message: response.data?.message });
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50 " onClose={handleClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-800 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto ">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full  max-w-3xl  transform overflow-hidden bg-white rounded-2xl shadow-xl transition-all">
                <div className="bg-[#F5F5F5] p-5 grid grid-cols-3 items-center">
                  <div
                    className="flex flex-row items-center gap-2 text-left"
                    onClick={handleClose}
                  >
                  <img src={close_icon} alt="Close"/>
                    <p>Close</p>
                  </div>
                  <div className="mx-auto">Upload National ID</div>
                  <div></div>
                </div>
                <section className="p-5">
                  <Form
                    form={form}
                    layout="vertical"
                    className="w-full"
                    data-testid="edit-verification-submit-form"
                    onFinish={handleSubmit}
                  >
                    <div className="w-full flex items-center flex-wrap">
                      <Form.Item
                        label={
                          <label className="block capitalize text-sm font-semibold">
                            National ID Number
                          </label>
                        }
                        className="w-full mb-2 pr-3"
                        name="serial"
                        colon={false}
                      >
                        <Input
                          data-testid="edit-verification-country-id-input"
                          type="text"
                          placeholder="GHA-67348994-0"
                          autoComplete="off"
                          value={details.serial}
                          disabled={loading}
                          size="large"
                          onChange={(e) =>
                            setDetails((prevData) => ({
                              ...prevData,
                              serial: e.target.value,
                            }))
                          }
                          className="w-full px-4 mt-2 mb-4 border border-gray-200 rounded-md outline-none h-10"
                        />
                      </Form.Item>
                    </div>
                    <div className="w-full flex items-center flex-wrap">
                      <Form.Item
                        colon={false}
                        label="Issued Date"
                        name="issued_date"
                        rules={[
                          { required: true, message: "This field is required" },
                        ]}
                        className="w-1/2 mb-2 pr-3"
                      >
                        <Input
                          type="date"
                          value={details.issued_at}
                          onChange={(e) =>
                            setDetails((prevData) => ({
                              ...prevData,
                              issued_at: e.target.value,
                            }))
                          }
                          className="h-12"
                          autoComplete="off"
                          size="large"
                        />
                      </Form.Item>
                      <Form.Item
                        colon={false}
                        label="Expiry Date"
                        name="expiry_date"
                        rules={[
                          { required: true, message: "This field is required" },
                        ]}
                        className="w-1/2 mb-2 pr-3"
                      >
                        <Input
                          type="date"
                          value={details.expires_at}
                          onChange={(e) =>
                            setDetails((prevData) => ({
                              ...prevData,
                              expires_at: e.target.value,
                            }))
                          }
                          className="h-12"
                          autoComplete="off"
                          size="large"
                        />
                      </Form.Item>
                    </div>

                    <div className="mt-6 w-full flex items-center flex-wrap">
                      <Form.Item
                        name="front_image_url"
                        label={
                          <label className="block text-sm font-semibold">
                            ID card front image
                          </label>
                        }
                        className="w-1/2 mb-2 pr-3"
                        colon={false}
                      >
                        <ImageUpload
                          placeholder={details.front_image_url}
                          uploadImage={(url, file) => {
                            // handleSignImage("front", url, file);
                            //uploadSignedImage("front", url, file);
                            handleImageUpload("front", url, file);
                          }}
                        />
                      </Form.Item>
                      <Form.Item
                        name="back_image_url"
                        label={
                          <label className="block text-sm font-semibold">
                            ID card back image
                          </label>
                        }
                        className="w-1/2 mb-2 pr-3"
                        colon={false}
                      >
                        <ImageUpload
                          placeholder={details.back_image_url}
                          uploadImage={(url, file) => {
                            // handleSignImage("back", url, file);
                            handleImageUpload("back", url, file);
                          }}
                        />
                      </Form.Item>
                    </div>
                    <div className="w-full flex justify-end items-end mt-6 mb-10">
                      <div className="mr-2">
                        <button
                          onClick={() => handleClose()}
                          className="rounded-md border font-bold capitalize px-7 py-3 border-red-101 text-red-101"
                        >
                          cancel
                        </button>
                      </div>
                      <div>
                        <button
                          disabled={disabled}
                          className="flex rounded-md bg-red-101 text-white capitalize  px-7 py-3"
                        >
                          {loading && <Loader />}
                          upload
                        </button>
                      </div>
                    </div>
                  </Form>
                </section>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default EditVerificationModal;

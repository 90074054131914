import React, { ChangeEvent, useState, Fragment, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Form, notification, Input, Select, Skeleton } from "antd";
import PhoneInput, { Value } from "react-phone-number-input";
import { accounts_ms, ride_ms } from "../helpers/api";
import { useQuery } from "@tanstack/react-query";
import { RouteConstants } from "../helpers/constants";
import Loader from "./Loader";
import { validateEmail } from "../helpers/utils";
import close_icon from "../img/close.svg"

const BasicInfoModal = ({
  isOpen,
  handleClose,
}: {
  isOpen: boolean;
  handleClose: () => void;
}) => {
  const [gender, setGender] = useState("");
  const [ice1_phone, setIce1Phone] = useState<Value | undefined>("");
  const [ice2_phone, setIce2Phone] = useState<Value | undefined>("");
  const [network_providers, setNetworkProviders] = useState<any>([]);
  const [paymentNumber, setPaymentNumber] = useState<Value | undefined>("");
  const [paymentProvider, setPaymentProvider] = useState<Value | undefined>("");
  const [loading, setLoading] = useState(false);
  const [phone, setPhone] = useState<Value | undefined>("");
  const [form] = Form.useForm();

  const [user_data, setUserData] = useState({
    image: "",
    first_name: "",
    middle_name: "",
    last_name: "",
    birthdate: "",
    email: "",
    country: { 
      name: "",
      code: "",
      phone_code: "",
    },
    gender: "",
    payment_account_number: "",
    payment_account_provider: "",
  });

  const { isLoading } = useQuery({
    retry: 1,
    queryKey: ["user-details"],
    queryFn: async () => {
      try {
        const user = await accounts_ms.get(RouteConstants.PROFILE);
        //console.log(user.data.data);
        setUserData(user.data?.payload);
        setGender(user.data?.payload?.gender);
        setPhone(user.data?.payload?.phone);
        setIce1Phone(user.data?.payload?.ice1_phone);
        setIce2Phone(user.data?.payload?.ice2_phone);
        setPaymentNumber(user.data?.payload?.payment_account_number);
        setPaymentProvider(user.data?.payload?.payment_account_provider);
          console.log("Edit Details",user.data?.payload )
        return user.data?.payload;
      } catch (e) {
        console.log(e);
      }
    },
  });

  const handleSubmit = () => {
    let formatIce1Phone = ice1_phone?.replace(/\s+/g, "");
    let formatIce2Phone = ice2_phone?.replace(/\s+/g, "");
    let account_provider_number = paymentNumber?.replace(/\s+/g, "");
    let emailValue = form.getFieldValue("email");
    setLoading(true);

    form
      .validateFields([
        "ice1_phone",
        "ice2_phone",
        "payment_account_provider",
        "payment_account_number",
      ])
      .then((val) => {
        //console.log(val);
        accounts_ms
          .put(RouteConstants.PROFILE, {
            ...user_data,
            first_name: user_data.first_name,
            last_name: user_data.last_name,
            middle_name: user_data.middle_name,
            country: user_data.country.name,
            gender: gender,
            email: emailValue,
            ice1_phone: formatIce1Phone,
            ice2_phone: formatIce2Phone,
            birthdate: user_data.birthdate,
            payment_account_provider: val?.payment_account_provider,
            payment_account_number: account_provider_number,
          })
          .then((response) => {
            // console.log(user_data);
            notification.success({ message: "Profile has been updated!" });
            setLoading(false);
            handleClose()
          window.location.reload();

          })
          .catch((e: any) => {
            notification.error({
              message: e?.response?.data?.message || e?.message,
            });
            console.log(e);
          })
          .finally(() => setLoading(false));
      })
      .catch((e) => {
        //handle errors
        setLoading(false);
      });
  };

  const getProviders = async () => {
    try {
      const { data } = await ride_ms.get(RouteConstants.NETWORK_PROVIDERS);
      setNetworkProviders(
        data?.payload?.items?.map((item: any) => ({
          label: item?.name,
          value: item?.code,
        }))
      );
    } catch (e) {
      console.log(e);
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const specialCharactersRegex = /[!@#$%^&*(),.?":{}|<>]/;
    const emojiRegex =
      /[\u{1F300}-\u{1F64F}\u{1F680}-\u{1F6FF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}\u{1F900}-\u{1F9FF}\u{1F1E6}-\u{1F1FF}\u{1F191}-\u{1F251}\u{1F600}-\u{1F64F}\u{1F680}-\u{1F6FF}\u{1F1E6}-\u{1F1FF}]/u;

    // Check if the input value contains any special characters
    const containsSpecialCharacters = specialCharactersRegex.test(value);
    const emojiCharacters = emojiRegex.test(value);

    if (containsSpecialCharacters && emojiCharacters) {
      notification.error({
        message: "First name cannot contain special character",
      });
    } else {
      setUserData((prevData) => ({ ...prevData, first_name: value }));
    }
  };

  useEffect(() => {
    getProviders();
  }, [paymentProvider]);

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50 " onClose={handleClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-800 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto ">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full  max-w-3xl  transform overflow-hidden bg-white rounded-2xl shadow-xl transition-all">
                <div className="bg-[#F5F5F5] p-5 grid grid-cols-3 items-center">
                  <div
                    className="flex flex-row items-center gap-2 text-left"
                    onClick={handleClose}
                  >
                  <img src={close_icon} alt="Close"/>
                    <p>Close</p>
                  </div>
                  <div>Edit Profile</div>
                  <div></div>
                </div>
                <section className="p-5">
                  <Form
                    form={form}
                    layout="vertical"
                    className="w-full"
                    data-testid="edit-profile-submit-form"
                    onFinish={(e) => {
                      //e.preventDefault();
                      handleSubmit();
                    }}
                  >
                    <div className="w-full flex items-center flex-wrap">
                      <Form.Item
                        label={
                          <label
                            htmlFor="first_name"
                            className="block capitalize text-sm text-gray-601"
                          >
                            First name
                          </label>
                        }
                        rules={[
                          {
                            required: true,
                            message: "This field is required",
                          },
                          {
                            validator: (_, value) =>
                              !value?.includes(" ")
                                ? Promise.resolve()
                                : Promise.reject(
                                    new Error("No spaces allowed")
                                  ),
                          },
                        ]}
                        className="w-full md:w-1/3 mb-2 pr-3"
                        colon={false}
                      >
                        {isLoading ? (
                          <Skeleton />
                        ) : (
                          <Input
                            data-testid="edit-profile-first-name-input"
                            type="text"
                            minLength={2}
                            autoComplete="off"
                            value={user_data.first_name}
                            onChange={handleInputChange}
                            size="large"
                            name="first_name"
                            className="w-full px-4 mt-2 mb-4 border border-gray-200 rounded-md outline-none h-10"
                          />
                        )}
                      </Form.Item>
                      <Form.Item
                        label={
                          <label
                            htmlFor="middle_name"
                            className="block capitalize text-sm text-gray-601"
                          >
                            Middle name (optional)
                          </label>
                        }
                        className="w-full md:w-1/3 mb-2 pr-3"
                        colon={false}
                      >
                        {isLoading ? (
                          <Skeleton />
                        ) : (
                          <Input
                            data-testid="edit-profile-middle-name-input"
                            type="text"
                            autoComplete="off"
                            value={user_data.middle_name}
                            onChange={(e) =>
                              setUserData((prevData) => ({
                                ...prevData,
                                middle_name: e.target.value,
                              }))
                            }
                            size="large"
                            name="middle_name"
                            className="w-full px-4 mt-2 mb-4 border border-gray-200 rounded-md outline-none h-10"
                          />
                        )}
                      </Form.Item>

                      <Form.Item
                        label={
                          <label
                            htmlFor="last_name"
                            className="block capitalize text-sm text-gray-601"
                          >
                            Last name
                          </label>
                        }
                        className="w-full md:w-1/3 mb-2 pr-3"
                        colon={false}
                      >
                        {isLoading ? (
                          <Skeleton />
                        ) : (
                          <Input
                            data-testid="edit-profile-last-name-input"
                            type="text"
                            minLength={2}
                            autoComplete="off"
                            value={user_data.last_name}
                            onChange={(e) =>
                              setUserData((prevData) => ({
                                ...prevData,
                                last_name: e.target.value,
                              }))
                            }
                            size="large"
                            name="last_name"
                            className="w-full px-4 mt-2 mb-4 border border-gray-200 rounded-md outline-none h-10"
                          />
                        )}
                      </Form.Item>
                    </div>
                    <div className="w-full flex items-center flex-wrap">
                      <Form.Item
                        label={
                          <label
                            htmlFor="dob"
                            className="block capitalize text-sm text-gray-601"
                          >
                            Date Of Birth
                          </label>
                        }
                        className="w-full md:w-1/2 mb-2 pr-3"
                        colon={false}
                      >
                        {isLoading ? (
                          <Skeleton />
                        ) : (
                          <Input
                            data-testid="edit-profile-dob-input"
                            type="date"
                            value={user_data.birthdate}
                            autoComplete="off"
                            size="large"
                            onChange={(e) =>
                              setUserData((prevData) => ({
                                ...prevData,
                                birthdate: e.target.value,
                              }))
                            }
                            // onChange={handleDateOfBirthChange}
                            name="dob"
                            className="w-full px-4 mt-2 mb-4 border border-gray-200 rounded-md outline-none h-10"
                          />
                        )}
                      </Form.Item>

                      <Form.Item
                        colon={false}
                        label={
                          <label className="block capitalize text-sm text-gray-601">
                            Gender
                          </label>
                        }
                        name="gender"
                        className="w-full md:w-1/2 mb-2 pr-3"
                      >
                        {isLoading ? (
                          <Skeleton />
                        ) : (
                          <Select
                            size="large"
                            value={gender}
                            onChange={(value: string) => {
                              setGender(value);
                              form.setFieldsValue({ gender: value });
                            }}
                            className="w-1/2 mb-2 p-3 text-left custom-select"
                            data-testid="edit-profile-gender-select"
                            options={[
                              {
                                value: "M",
                                label: "Male",
                              },
                              {
                                value: "F",
                                label: "Female",
                              },
                            ]}
                          />
                        )}{" "}
                      </Form.Item>
                    </div>
                    <div className="w-full flex items-center flex-wrap">
                      <Form.Item
                        label={
                          <label
                            htmlFor="phone"
                            className="block capitalize text-sm text-gray-601"
                          >
                            Phone Number
                          </label>
                        }
                        className="w-full md:w-1/2 mb-2 pr-3"
                        colon={false}
                        rules={[
                          {
                            required: true,
                            message: "This field is required",
                          },
                        ]}
                      >
                        <PhoneInput
                          id="phone"
                          className="phone w-full px-4 mt-2 mb-4 border border-gray-200 rounded-md outline-none h-10"
                          data-testid="edit-profile-emergency-1-input"
                          defaultCountry="GH"
                          name="phone"
                          value={phone}
                          onChange={(e) => setPhone(e)}
                        />
                      </Form.Item>
                      <Form.Item
                        rules={[{ validator: validateEmail }]}
                        label={
                          <label
                            htmlFor="email"
                            className="block capitalize text-sm text-gray-601"
                          >
                            Email
                          </label>
                        }
                        className="w-full md:w-1/2 mb-1 pr-3"
                        colon={false}
                        name="email"
                      >
                        <Input
                          data-testid="edit-profile-email-input"
                          type="text"
                          minLength={2}
                          autoComplete="off"
                          value={user_data.email}
                          onChange={(e) =>
                            setUserData((prevData) => ({
                              ...prevData,
                              email: e.target.value,
                            }))
                          }
                          size="large"
                          className="w-full px-4 mb-4 border border-gray-200 rounded-md outline-none h-10"
                        />{" "}
                      </Form.Item>
                    </div>
                    <div className="w-full flex items-center flex-wrap my-4">
                      <Form.Item
                        label={
                          <label
                            htmlFor="ice1_phone"
                            className="block capitalize text-sm text-gray-601"
                          >
                            Primary Emergency
                          </label>
                        }
                        className="w-full md:w-1/2 mb-2 pr-3"
                        colon={false}
                        rules={[
                          {
                            required: true,
                            message: "This field is required",
                          },
                        ]}
                      >
                        <PhoneInput
                          id="phone1"
                          className="phone w-full px-4 mt-2 mb-4 border border-gray-200 rounded-md outline-none h-10"
                          data-testid="edit-profile-emergency-1-input"
                          defaultCountry="GH"
                          name="ice1_phone"
                          value={ice1_phone}
                          onChange={(e) => setIce1Phone(e)}
                        />
                      </Form.Item>
                      <Form.Item
                        label={
                          <label
                            htmlFor="ice2_phone"
                            className="block capitalize text-sm text-gray-601"
                          >
                            Secondary Emergency
                          </label>
                        }
                        className="w-full md:w-1/2 mb-2 pr-3"
                        colon={false}
                        rules={[
                          {
                            required: true,
                            message: "This field is required",
                          },
                        ]}
                      >
                        <PhoneInput
                          id="phone2"
                          className="phone w-full px-4 mt-2 mb-4 border border-gray-200 rounded-md outline-none h-10 "
                          data-testid="edit-profile-emergency-1-input"
                          defaultCountry="GH"
                          autoComplete="off"
                          name="ice2_phone"
                          value={ice2_phone}
                          onChange={(e) => setIce2Phone(e)}
                        />
                      </Form.Item>
                    </div>
                    <div className="w-full flex items-center flex-wrap my-4">
                      <Form.Item
                        name="payment_account_provider"
                        label={
                          <label className="block capitalize text-sm text-gray-601 custom-select">
                            Payment network provider
                          </label>
                        }
                        className="w-full mb-2 pr-3"
                        colon={false}
                      >
                        {isLoading || !network_providers.length  ? (
                          <Skeleton />
                        ) : (
                          <Select
                            size="large"
                            value={paymentProvider || []}
                            className="w-1/2 mb-2 pr-3 text-md text-left custom-select"
                            options={network_providers}
                            onChange={(e: any) => {
                              setPaymentProvider(e);
                              form.setFieldsValue({payment_account_provider: e});
                            }}
                          />
                        )}{" "}
                      </Form.Item>
                      <Form.Item
                        name="payment_account_number"
                        label={
                          <label
                            htmlFor="payment_account_number"
                            className="block capitalize text-sm text-gray-601"
                          >
                            Payment phone number
                          </label>
                        }
                        className="w-full mb-2 pr-3"
                        colon={false}
                      >
                        <PhoneInput
                          id="providerphone"
                          className="phone w-full px-4 mt-2 mb-4 border border-gray-200 rounded-md outline-none h-10 "
                          defaultCountry="GH"
                          autoComplete="off"
                          value={paymentNumber}
                          onChange={(e) => setPaymentNumber(e)}
                        />{" "}
                      </Form.Item>
                    </div>
                    <div className="w-full flex justify-end items-center mt-6 mb-6 text-[#B40303] ">
                      <div className="mr-2">
                        <button
                          onClick={() => handleClose()}
                          className="rounded-lg border border-[#B40303] font-bold capitalize px-8 py-2"
                          disabled={loading}
                          type="button"
                        >
                          cancel
                        </button>
                      </div>
                      <div>
                        <button
                          type="submit"
                          className="rounded-lg flex bg-red-101 text-white capitalize px-8 py-2"
                          disabled={loading}
                          onClick={() => handleSubmit()}
                        >
                          {loading && <Loader />}
                          Save
                        </button>
                      </div>
                    </div>
                  </Form>
                </section>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default BasicInfoModal;

import { useState } from "react";
import { Navigate } from "react-router-dom";
import cookies from "js-cookie";
import { COOKIES_USR, COOKIES_TOKEN_NAME } from "../../helpers/constants";
import Navbar from "../../components/Navbar";
import Sidenav from "../../components/Sidenav";
import ChangePinModal from "../../components/ChangePinModal";
import { Form } from "antd";

const Security = () => {
  const [reset_pin_modal, openResetPinModal] = useState<boolean>(false);
  const userId = cookies.get(`${COOKIES_USR}`);
  const token = cookies.get(`${COOKIES_TOKEN_NAME}`);
  const [form] = Form.useForm();

  if (userId && token) {
    return (
      <>
        <div>
          <Navbar title="Security" />
          <Sidenav />
          <section>
            <div className="w-full md:w-[79%] lg:w-[88.3%] float-right  px-[2%]">
              <div className="w-full mt-[110px] md:mt-[150px] rounded-[30px] border-[1px]  border-[#D4D3D3] p-5 ">
                <div className="flex flex-row mb-7 items-center justify-between ">
                  <p className="font-bold text-2xl text-[#484442]">PIN</p>
                  <img
                    src={require("../../img/change_pin.png")}
                    alt="Change pin"
                    onClick={() => openResetPinModal(true)}
                    className="cursor-pointer"
                  />
                </div>
                <div className="">
                  <p>Current pin</p>
                  <p>***********</p>
                </div>
              </div>
            </div>
          </section>
        </div>
        <ChangePinModal
          form={form}
          isOpen={reset_pin_modal}
          handleClose={() => openResetPinModal(false)}
        />
      </>
    );
  } else {
    return <Navigate to="/login" />;
  }
};

export default Security;

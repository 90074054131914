import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Form, notification, Input } from "antd";
import Secured from "../img/secured_email.png";
import Cross from "../img/close-line.png";
import { API_URL } from "../helpers/constants";
import Loader from "./Loader";
import useLocalStorage from "use-local-storage";
import PhoneInput, { Value } from "react-phone-number-input";
import axios from "axios";

type Props = {
  isOpen: boolean;
  handleClose: () => void;
};

const ForgotPin = (props: Props) => {
  //let open = props.isOpen;
  const [username, setUsername] = useLocalStorage<string>("phone_number", "");
  const [form] = Form.useForm();
  const [resending, setResending] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [user_Data, setUserData] = useState({
    phone: undefined as Value | undefined,
    pin: "",
    otp: "",
  });
  const [step, setStep] = useState(1);

  const submit = () => {
    setSubmitting(true);
    let formatting = username.replace(/\s+/g, "");
    const endpoint =
      step === 1 ? "v2/auth/pin/request-reset-code" : "v2/auth/pin/reset";

    const payload =
      step === 1
        ? { phone: formatting }
        : { phone: username, code: user_Data.otp, pin: user_Data.pin };

    axios
      .post(`${API_URL}/${endpoint}`, payload, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const data = response.data;

        setSubmitting(false);

        if (data?.message === "OK") {
          if (step === 1) {
            setStep(2);
          } else {
            // Delete phone number from local storage
            localStorage.removeItem("phone_number");
            close();
            notification.success({ message: "Your PIN has been changed" });
          }
        } else {
          notification.error({ message: data.message });
        }
      })
      .catch((e) => {
        setSubmitting(false);
        notification.error({ message: "That did not work. Try again later" });
      });
  };

  const resend = () => {
    setResending(true);
    setSubmitting(true);
    let formatting = username.replace(/\s+/g, "");

    const endpoint = "v2/auth/pin/request-reset-code";

    const payload = { phone: formatting };

    axios
      .post(`${API_URL}/${endpoint}`, payload, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const output = response.data;
        if (output?.message === "OK") {
          notification.success({ message: "OTP resend successful" });
        } else {
          notification.error({ message: output?.message });
        }
      })
      .catch((e) => {
        notification.error({ message: "That did not work! Try again later" });
      })
      .finally(() => {
        setResending(false);
        setSubmitting(false);
      });
  };

  const close = () => {
    setStep(1);
    form.resetFields();
    props.handleClose();
  };

  return (
    <Transition.Root
      show={props.isOpen}
      as={Fragment}
      data-testid="reset-pin-modal"
    >
      {/* <CheckPin isOpen={openCheckPin} handleClose={() => setCheckPin(false)} /> */}
      <Dialog as="div" className="relative z-50" onClose={close}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-800 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="relative text-left p-4 sm:p-6 md:p-10 bg-oya-ghana-modal-background border border-oya-ghana-border shadow-form rounded-xl">
                <div className="flex items-start justify-between">
                  <div className="flex items-start">
                    <img src={Secured} alt="Secured phone" />
                    <h3 className="text-oya-ghana-header-h5 pb-5 pl-4">
                      Did You Forget <br className="hidden sm:block" /> Your
                      PIN?
                    </h3>
                  </div>

                  <div
                    className="border-2 border-oya-ghana-border p-2 rounded-full cursor-pointer ml-4"
                    onClick={close}
                  >
                    <img
                      src={Cross}
                      alt="close button"
                      style={{ width: 20, height: 20 }}
                    />
                  </div>
                </div>

                <Form
                  className="w-full"
                  form={form}
                  layout="vertical"
                  onFinish={submit}
                >
                  {step === 1 && (
                    <Form.Item
                      label={
                        <label
                          htmlFor="phone"
                          className="block capitalize text-sm text-gray-601"
                        >
                          Enter your phone number
                        </label>
                      }
                      name="phone"
                      className="w-full mb-2 pr-3"
                      rules={[
                        { required: true, message: "This field is required" },
                      ]}
                      colon={false}
                      required={true}
                    >
                      <PhoneInput
                        id="phone"
                        name="phone"
                        className="w-full px-4 mt-2 mb-4 border border-gray-200 rounded-md outline-none h-10 focus:border-gray-200 focus:outline-none"
                        data-testid="login-phone-input"
                        defaultCountry="GH"
                        disabled={submitting}
                        value={user_Data.phone}
                        onChange={(e) => {
                          setUsername(e);
                          setUserData((prevData) => ({
                            ...prevData,
                            phone: e,
                          }));
                        }}
                      />
                    </Form.Item>
                  )}

                  {step === 2 && (
                    <>
                      <p className="text-sm py-5">
                        We’ve sent you a One Time Pin to your SMS inbox, <br />
                        enter it below to get started.
                      </p>

                      <Form.Item
                        colon={false}
                        name="otp"
                        label={
                          <div className="text-gray-500 text-sm">
                            OTP Sent To Your Number
                          </div>
                        }
                        className="w-full mb-6"
                        rules={[
                          {
                            required: true,
                            message: "This field is required",
                          },
                        ]}
                      >
                        <Input
                          data-testid="reset-pin-otp-input"
                          type="text"
                          autoComplete="off"
                          size="large"
                          className="w-full px-4 mt-2 bg-white border border-gray-200 outline-none rounded-md h-10"
                          disabled={submitting}
                          onChange={(e) => {
                            setUserData((prevState) => ({
                              ...prevState,
                              otp: e.target.value,
                            }));
                          }}
                        />
                      </Form.Item>

                      <Form.Item
                        colon={false}
                        name="pin"
                        label={
                          <div className="text-gray-500 text-sm">
                            Enter Your New PIN
                          </div>
                        }
                        className="w-full mb-6"
                        rules={[
                          {
                            required: true,
                            message: "This field is required",
                          },
                        ]}
                      >
                        <Input
                          data-testid="reset-pin-new-pin-input"
                          type="text"
                          autoComplete="off"
                          size="large"
                          className="w-full px-4 mt-2 bg-white border border-gray-200 outline-none rounded-md h-10"
                          disabled={submitting}
                          maxLength={4}
                          onChange={(e) => {
                            setUserData((prevState) => ({
                              ...prevState,
                              pin: e.target.value,
                            }));
                          }}
                        />
                      </Form.Item>
                    </>
                  )}

                  {step === 2 && (
                    <button
                      data-testid="reset-pin-resend-button"
                      type="submit"
                      className="w-full trans flex items-center justify-center mt-5 border border-red-101 text-red-101 text-oya-ghana-header-h6 rounded-md py-2"
                      onClick={resend}
                      disabled={resending}
                    >
                      Resend
                    </button>
                  )}

                  <button
                    data-testid="reset-pin-continue-button"
                    type="submit"
                    disabled={submitting === true ? submitting : resending}
                    className="relative w-full trans flex items-center justify-center my-5 bg-red-101 text-oya-ghana-header-h6 rounded-md py-2 text-white disabled:cursor-pointer"
                  >
                    {submitting ? <Loader /> : "Continue"}
                  </button>
                </Form>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ForgotPin;

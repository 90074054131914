import React, { useState, useRef } from "react";

interface OTPInputProps {
  length?: number;
  onComplete: (pin: string) => void;
}

const OTPInput: React.FC<OTPInputProps> = ({ length = 6, onComplete }) => {
  const [otp, setOtp] = useState<string>(""); // Initialize OTP as a string
  const inputRef = useRef<(HTMLInputElement | null)[]>([]);

  const handleChange = (input: string, index: number) => {
    const newOtp = otp.split(""); // Convert the string to an array of characters

    // Update the value at the specified index
    if (input.length <= 1) {
      newOtp[index] = input; // Set the digit at the current index
      setOtp(newOtp.join("")); // Update the state with the new OTP string
    }

    // Move focus to the next input
    if (input.length === 1 && index < length - 1) {
      inputRef.current[index + 1]?.focus();
    }

    // Move focus back to the previous input if the input is empty
    if (input.length === 0 && index > 0) {
      inputRef.current[index - 1]?.focus();
    }

    // Check if the OTP is complete and call onComplete
    if (newOtp.length === length && !newOtp.includes("")) {
      onComplete(newOtp.join("")); // Call onComplete with the complete OTP
    }
  };

  return (
    <div className=" max-w-[500px] grid grid-cols-6 gap-2 md:gap-5">
      {Array.from({ length }, (_, index) => (
        <input
          key={index}
          type="text"
          maxLength={1}
          value={otp[index] || ""}
          onChange={(e) => handleChange(e.target.value, index)}
          ref={(ref) => (inputRef.current[index] = ref as HTMLInputElement)}
          className="border-2 border-solid rounded-lg border-gray-400 max-w-[80px] focus:border-blue-600 p-2 md:p-5 outline-none"
        />
      ))}
    </div>
  );
};

export default OTPInput;
